import React, { useEffect, useState } from 'react';
import { InputLabel, MenuItem, FormControl, Select } from '@mui/material';
import Typography from '@mui/material/Typography';

/**
 *
 * @param {{
 *  defaultVal: string;
 *  onChangeDateRange: (dateRange: string) => void;
 *  timeDurationOptions: {label:string, isDisabled?:boolean}[];
 * }} param0
 */
export default function ({
  defaultVal,
  onChangeDateRange,
  timeDurationOptions: options
}) {
  const [value, setValue] = useState(defaultVal);

  const handleChange = (event) => {
    setValue(event.target.value);
    onChangeDateRange(event.target.value);
  };

  return (
    <FormControl sx={{ m: 1, border: 'none', margin: 0 }} size="small">
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={value || ''}
        onChange={handleChange}
        MenuProps={{
          disableScrollLock: true
        }}
        sx={{
          color: '#003F2D',
          fontSize: '14px',
          fontWeight: 500,
          boxShadow: 'none',
          height: 35,
          textAlign: 'end',
          '.MuiSelect-select': { padding: 0 },
          '.MuiOutlinedInput-notchedOutline': { border: 0 },
          '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            border: 0
          },
          '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
            {
              border: 0
            }
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.label}
            value={option.label}
            disabled={option.isDisabled}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
