import './index.scss';

import React from 'react';
import ReactDOMClient from 'react-dom/client';
import App from './App.js';
// import './utils/types.js';

import { initConfig } from './config/client/config';

let main = async () => {
  // startup logic between here -----
  //await loadMicroAppNavigation();
  // ----------------
  await initConfig();

  const root = ReactDOMClient.createRoot(document.getElementById('container-root'));

  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
};

main();
