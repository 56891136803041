import './UserProfileDropdown.scss';

import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';

import OktaAuth from '../../utils/okta.js';
import Api from '../../utils/Api.js';

import { ContactMail, Logout } from '../theme/Icons.jsx';
import { userData } from '../../utils/okta.js';
import MultiProjects from '../multiProjects/MultiProjects.jsx';

import { getConfig } from '../../config/client/config.js';

let getInitials = (fname, lname) => {
  let first = fname ? fname[0] : '';
  let last = lname ? lname[0] : '';
  return `${first}${last}`;
};

const UserProfileDropdown = React.forwardRef((props, ref) => {
  let config = getConfig();

  // let [projectList, setProjectList] = useState([]);

  let user = userData();

  // const getApplicationList = async () => {
  //   try {
  //     //   setisLoading(true);
  //     let oktaToken = JSON.parse(localStorage.getItem("okta-token-storage"));

  //     let response = await Api.get("/getProjectsByUser/" + user.aud);

  //     if (response !== "") {
  //       setProjectList(response.data);
  //     } else {
  //       setProjectList([]);
  //     }
  //   } catch (error) {
  //     setProjectList([]);
  //   }
  // };

  // useEffect(() => {
  //   getApplicationList();
  // }, []);

  let navigate = useNavigate();

  let logoutHandler = () => {
    OktaAuth.logout();
    navigate('/logout/oidc/callback');
  };

  let initials = getInitials(user.firstname, user.lastname);

  return (
    <Menu
      id="basic-menu"
      anchorEl={props.anchorEl}
      open={props.open}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      onClose={props.hideDropdown}
      className={'user-profile-dropdown'}
      sx={{ pointerEvents: 'none', marginTop: '8px' }}
      PaperProps={{
        sx: {
          pointerEvents: 'auto'
        }
      }}
    >
      <div ref={ref}>
        <Box
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '320px',
            minWidth: '320px'
          }}
        >
          <Avatar sx={{ bgcolor: '#003F2D' }}>{initials}</Avatar>
          <Box sx={{ marginLeft: '16px' }}>
            <Typography
              variant="h6"
              sx={{ letterSpacing: 0 }}
            >{`Welcome, ${user.firstname}!`}</Typography>
            <Typography
              variant="subtitle2"
              sx={{ color: '#1A1A1A99', letterSpacing: 0 }}
            >
              {user.email}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <MenuItem onClick={() => logoutHandler()}>
          <div className="user-profile-dropdown-menu-item">
            <Logout
              sx={{
                fontSize: '18px',
                color: '#1A1A1A',
                opacity: 0.6,
                marginLeft: '5px'
              }}
            />
            <span className="user-profile-dropdown-menu-item-text">
              Log out
            </span>
          </div>
        </MenuItem>
      </div>
    </Menu>
  );
});
export default UserProfileDropdown;
