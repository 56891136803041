import content from './config.smartfm.json' assert { type: 'JSON' };

// ${NX_ENV} special syntax
// we use a special envsub at `npm run start` to select the right env config
let NX_ENV_UNDEF = process.env.NX_ENV == 'undefined' || !process.env.NX_ENV;
let nxEnv = NX_ENV_UNDEF ? 'local-smartfm' : '${NX_ENV}'; // tokenized NX_ENV
let env = nxEnv;
let config = null;

let setDefaultConfig = () => {
  env = nxEnv.split('-')[0];
  let _config = content;
  config = Object.assign(_config['default'], _config[env]);
};

let initConfig = async () => {
  setDefaultConfig();
  return config;
};

let getConfig = () => {
  return config;
};

// special mechanism for getting okta config
// the okta.js file has a special init func where we can't async load
let getOktaConfig = () => {
  setDefaultConfig();
  return config.okta;
};

export { getConfig, getOktaConfig, initConfig };
