import { Cascader } from '@smartfm/react-components';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { api } from './../utils/Api';
import React from 'react';
import { useNavigationCtx } from './NavigationProvider';
import { Button } from '@mui/material';
import { setDefaultDashboard } from '../services/DashboardService';

export function CascaderInstance() {
  const appName = 'Container UI';
  const [searchParams, setSearchParams] = useSearchParams();
  const recordId = searchParams.get('recordId');
  const navigationCtx = useNavigationCtx();
  const recordIdValue = recordId === 'null' ? null : recordId;
  const defaultDashboard = useQuery({
    queryKey: ['getDefaultSiteForUser'],
    queryFn:
      /** @returns {Promise<{data:{site: string;}[];message: string;error: boolean;}>} */
      () =>
        api
          .post('admin/getDefaultSiteForUser', {
            defaultDashboard: true
          })
          .then((res) => {
            const firstSiteName = res.data?.data.at(0)?.site;
            console.log(firstSiteName);
            searchParams.set('recordId', firstSiteName ?? 'null');
            setSearchParams(searchParams, { replace: false });
            return res.data;
          })
          .catch(() => null),
    enabled: !recordId
  });

  const useQueryResult = useQuery({
    queryKey: ['cascadenavigation', recordId, appName],
    queryFn:
      /**
       * @returns {Promise<{ data: import("@smartfm/react-components").NavNode }>}
       */
      () =>
        api.post('navigation/cascadenavigation', {
          id: recordIdValue,
          cascader: recordIdValue === null ? 'true' : 'false',
          appName
        }),
    enabled: !!recordId
  });
  const useMutationResult = useMutation({
    mutationKey: ['cascadenavigation', appName],
    mutationFn:
      /**
       * @param {string} id
       */
      async (id) => {
        try {
          return (
            await api.post('navigation/cascadenavigation', {
              id,
              cascader: 'true',
              appName
            })
          ).data;
        } catch (error) {
          return null;
        }
      }
  });
  const useMutationDefaultDashboard = useMutation({
    mutationKey: ['defaultdashboard'],
    mutationFn: () => {
      try {
        return setDefaultDashboard(
          navigationCtx?.state.selectedOption.project_nexus_id,
          navigationCtx?.state.selectedOption.nexusId
        );
      } catch (err) {
        return Promise.resolve(null);
      }
    },
    onSuccess: () => defaultDashboard.refetch()
  });

  const setInitialId = useCallback(
    (id) => {
      searchParams.set('recordId', id || 'null');
      searchParams.delete('tab');
      setSearchParams(searchParams, { replace: false });
      useQueryResult.refetch();
    },
    [searchParams, setSearchParams]
  );

  const setSelectedOption = useCallback(
    (selectedOption) =>
      navigationCtx?.setState((prev) => ({ ...prev, selectedOption })),
    [navigationCtx?.setState]
  );
  const setAllOptions = useCallback(
    (allOptions) =>
      navigationCtx?.setState((prev) => ({ ...prev, allOptions })),
    [navigationCtx?.setState]
  );
  const onSelectedOptionChange = useCallback(
    (option) => setSelectedOption(option),
    [setSelectedOption]
  );
  const onAllOptionsChange = useCallback(
    (options) => setAllOptions(options),
    [setAllOptions]
  );
  const setBuildingAsDefault = () => useMutationDefaultDashboard.mutate();

  return (
    <div
      style={{
        height: '136px',
        paddingBottom: '16px'
      }}
    >
      <Cascader
        Link={Link}
        initialId={recordIdValue}
        onSelectedOptionChange={onSelectedOptionChange}
        onAllOptionsChange={onAllOptionsChange}
        useQueryResult={useQueryResult}
        useMutationResult={useMutationResult}
        setInitialId={setInitialId}
        secondaryComponent={
          navigationCtx?.state.selectedOption?.nexusId === recordIdValue &&
          navigationCtx?.state?.selectedOption?.navType == 'site' ? (
            <Button
              size="small"
              sx={{
                textTransform: 'none'
              }}
              disabled={
                defaultDashboard.data?.data.at(0)?.site === recordIdValue
              }
            >
              {defaultDashboard.data?.data.at(0)?.site === recordIdValue ? (
                'Default dashboard'
              ) : (
                <u onClick={() => setBuildingAsDefault()}>Set as default</u>
              )}
            </Button>
          ) : (
            ''
          )
        }
      />
    </div>
  );
}
