import React, { createContext, useState } from 'react';

let DashboardContext;
let { Provider } = (DashboardContext = createContext());

let DashboardProvider = ({ children }) => {
  let refreshTime = localStorage.getItem('refreshTime');
  if (refreshTime === null) {
    localStorage.setItem('refreshTime', '5');
    refreshTime = '5';
  }
  const [refreshDuration, setRefreshDuration] = useState(
    parseInt(refreshTime) || 5
  );
  const [refreshEvent, triggerRefreshEvent] = useState(false);
  const triggerRefresh = () => {
    triggerRefreshEvent((prevState) => !prevState);
  };
  return (
    <Provider
      value={{
        refreshDuration,
        setRefreshDuration,
        refreshEvent,
        triggerRefresh
      }}
    >
      {children}
    </Provider>
  );
};

export { DashboardContext, DashboardProvider };
