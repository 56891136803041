import './NavigationDrawer.scss';

import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import CircularProgress from '@mui/material/CircularProgress';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import axios from 'axios';

import Api from '../../utils/Api.js';

import { getConfig } from '../../config/client/config.js';

import {
  ChevronLeft,
  ChevronRight,
  AdminPanelSettings,
  Dashboard
} from '../theme/Icons';

import { navigation } from '../../MicroApps.js';

import React, { useEffect, useState, useContext } from 'react';
import { SidebarContext } from '../../context/SidebarContext.jsx';
import { Home } from '@mui/icons-material';

function getItem(label, link, key, icon, children, category) {
  return {
    key,
    icon,
    children,
    label,
    category,
    link
  };
}

const drawerWidth = 310;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}));

function NaviationDrawer({ parentCallback, pathname, onCollapse }) {
  const [searchParams, setSearchParams] = useSearchParams();
  let config = getConfig();

  const { isOpen: open, setIsOpen: setOpen } = useContext(SidebarContext);

  const [navItems, setNavItems] = useState(false);
  const [applicationList, setapplicationList] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [currentPath, setCurrentPath] = useState(window.location.pathname);
  const [categories, setCategories] = useState([]);
  const theme = useTheme();
  const [selected, setSelected] = useState(window.location.pathname);
  const location = useLocation();

  let getSubNavigation = async (applicationList) => {
    let _navigation = Object.assign({}, navigation);
    let children = null;
    applicationList.forEach((ni) => {
      let path = ni.key;
      //let navKey = microApps.filter((row) => row.navPath == path)[0]?.key;

      // if (navKey in _navigation) {
      //   children = Object.assign([], _navigation[navKey]);
      // }
    });
    let newItems = Object.assign(applicationList, {});
    //newItems[1].children = children;
    return newItems;
  };

  useEffect(() => {
    getSubNavigation(applicationList).then((newNav) => setNavItems(newNav));
    let itemCategories = applicationList.map((i) => i.category);
    setCategories([...new Set(itemCategories)]);
    getApplicationList();
    parentCallback(open);
  }, []);
  let onItemChange = () => {
    setCurrentPath(window.location.pathname);
  };

  useEffect(() => {
    setSelected(location.pathname);
  }, [location]);

  const getApplicationList = async () => {
    try {
      setisLoading(true);
      let oktaToken = JSON.parse(localStorage.getItem('okta-token-storage'));

      let response = await Api.get('/getUserPermissions');

      if (response != '') {
        const userIsAdmin = JSON.parse(localStorage.getItem('is-admin'));
        if (response.data.isAdmin) {
          localStorage.setItem(
            'is-admin',
            JSON.stringify(+response.data.isAdmin)
          );
        } else {
          localStorage.removeItem('is-admin');
        }
        let newApplicationList = response.data.permissions.directAccess.map(
          (applications) => {
            return getItem(
              applications.application_name,
              '/' +
                (applications.nav_path === 'ui/smart-cleaning'
                  ? 'ui/smart-cleaning'
                  : applications.nav_path),
              applications.application_name,
              <span className="material-symbols-outlined">
                {applications.icon_url}
              </span>,
              [],
              applications.category
            );
          }
        );

        newApplicationList.sort((a, b) => a.label.localeCompare(b.label));
        if (response.data.isAdmin === true) {
          newApplicationList.push(
            getItem(
              'Administration',
              '/ui/admin',
              'admin',
              <AdminPanelSettings />,
              [],
              'Admin'
            )
          );
        }

        setapplicationList(newApplicationList);
        setisLoading(false);
        return response;
      } else {
        window.location.reload(true);
      }
    } catch (error) {
      return {};
    }
  };

  const handleDrawer = () => {
    setOpen(!open);
    parentCallback(!open);
    localStorage.setItem('navdrawerstateopen', !open);
  };

  return (
    <Drawer variant="permanent" open={open}>
      <Toolbar />
      <Link to={'/'} style={{ textDecoration: 'none' }}>
        <ListItem disablePadding>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
              // background: selected === navItem.link ? '#003d2c1f' : '#fff',
              mx: '7px',
              borderRadius: '4px'
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 1 : 'auto',
                justifyContent: 'center',
                color: '#1A1A1A'
              }}
            >
              {/* {navItem.icon} */}
              <Dashboard />
            </ListItemIcon>
            <ListItemText
              // id={`sidebar-app-redirect-text-${navItem.label}`}
              primary={'Dashboard'}
              sx={{ opacity: open ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>
      </Link>
      <Divider
        sx={{
          my: '8px'
        }}
      />
      {applicationList.length > 0 ? (
        <List>
          {applicationList.map((navItem, index) => (
            <React.Fragment key={index}>
              {index == 0 ||
              navItem.category != applicationList[index - 1].category ? (
                open ? (
                  <ListItem key={navItem.category} disablePadding>
                    <ListItemButton
                      id={`sidebar-app-redirect-${navItem.category}`}
                    >
                      <ListItemText
                        primary={navItem.category}
                        sx={{
                          fontSize: '14px',
                          color: 'rgba(26, 26, 26, 0.6)',
                          display: open ? 'block' : 'none',
                          textTransform: 'capitalize'
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                ) : (
                  <Divider
                    sx={{
                      display: open || index === 0 ? 'none' : 'block',
                      my: '6px'
                    }}
                    key={index}
                  />
                )
              ) : (
                <></>
              )}
              {/* two possibilities... it's either / or /demo */}
              <Link
                key={`${index}-2`}
                to={
                  location.pathname.startsWith('/demo')
                    ? `/demo${navItem.link}`
                    : `${navItem.link}`
                }
                onClick={() => setSelected(navItem.link)}
                style={{ textDecoration: 'none' }}
              >
                <ListItem
                  key={navItem.key}
                  disablePadding
                  sx={{
                    display: 'block'
                  }}
                >
                  <ListItemButton
                    id={`sidebar-app-redirect-list-${navItem.label}`}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                      background:
                        selected === navItem.link ? '#003d2c1f' : '#fff',
                      mx: '7px',
                      borderRadius: '4px'
                    }}
                  >
                    <ListItemIcon
                      id={`sidebar-app-redirect-${navItem.label}`}
                      sx={{
                        minWidth: 0,
                        mr: open ? 1 : 'auto',
                        justifyContent: 'center',
                        color: '#1A1A1A'
                      }}
                    >
                      {navItem.icon}
                    </ListItemIcon>
                    <ListItemText
                      id={`sidebar-app-redirect-text-${navItem.label}`}
                      primary={navItem.label}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
                <Divider />
              </Link>
            </React.Fragment>
          ))}
        </List>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="20vh"
        >
          {isLoading ? (
            <CircularProgress size="1rem" />
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="20vh"
              fontSize="9px"
            >
              Sorry! you do not have access for any applications.
            </Box>
          )}
        </Box>
      )}

      <DrawerHeader>
        <IconButton onClick={handleDrawer} id="sidebar-toggle">
          {open ? <ChevronLeft /> : <ChevronRight />}
        </IconButton>
      </DrawerHeader>
    </Drawer>
  );
}

export default NaviationDrawer;

// Note: This code is for Accordion. Please do not remove it.
// import './NavigationDrawer.scss';

// import { styled, useTheme } from '@mui/material/styles';
// import {
//   Drawer as MuiDrawer,
//   AppBar as MuiAppBar,
//   Toolbar,
//   List,
//   Divider,
//   IconButton,
//   ListItem,
//   CircularProgress,
//   ListItemButton,
//   ListItemIcon,
//   ListItemText,
//   Accordion,
//   AccordionSummary,
//   AccordionDetails,
//   Typography,
//   Box
// } from '@mui/material';
// import { Link, useLocation } from 'react-router-dom';
// import axios from 'axios';
// import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';

// import Api from '../../utils/Api.js';

// import { getConfig } from '../../config/client/config.js';

// import {
//   ChevronLeft,
//   ChevronRight,
//   AdminPanelSettings,
//   Dashboard
// } from '../theme/Icons';

// import { navigation } from '../../MicroApps.js';

// import React, { useEffect, useState } from 'react';

// function getItem(label, link, key, icon, children, category) {
//   return {
//     key,
//     icon,
//     children,
//     label,
//     category,
//     link
//   };
// }

// const drawerWidth = 310;

// const openedMixin = (theme) => ({
//   width: drawerWidth,
//   transition: theme.transitions.create('width', {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.enteringScreen
//   }),
//   overflowX: 'hidden'
// });

// const closedMixin = (theme) => ({
//   transition: theme.transitions.create('width', {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen
//   }),
//   overflowX: 'hidden',
//   width: `calc(${theme.spacing(7)} + 1px)`,
//   [theme.breakpoints.up('sm')]: {
//     width: `calc(${theme.spacing(8)} + 1px)`
//   }
// });

// const DrawerHeader = styled('div')(({ theme }) => ({
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'flex-end',
//   padding: theme.spacing(0, 1),
//   // necessary for content to be below app bar
//   ...theme.mixins.toolbar
// }));

// const AppBar = styled(MuiAppBar, {
//   shouldForwardProp: (prop) => prop !== 'open'
// })(({ theme, open }) => ({
//   zIndex: theme.zIndex.drawer + 1,
//   transition: theme.transitions.create(['width', 'margin'], {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen
//   }),
//   ...(open && {
//     marginLeft: drawerWidth,
//     width: `calc(100% - ${drawerWidth}px)`,
//     transition: theme.transitions.create(['width', 'margin'], {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.enteringScreen
//     })
//   })
// }));

// const Drawer = styled(MuiDrawer, {
//   shouldForwardProp: (prop) => prop !== 'open'
// })(({ theme, open }) => ({
//   width: drawerWidth,
//   flexShrink: 0,
//   whiteSpace: 'nowrap',
//   boxSizing: 'border-box',
//   ...(open && {
//     ...openedMixin(theme),
//     '& .MuiDrawer-paper': openedMixin(theme)
//   }),
//   ...(!open && {
//     ...closedMixin(theme),
//     '& .MuiDrawer-paper': closedMixin(theme)
//   })
// }));

// function NaviationDrawer({ parentCallback, pathname, onCollapse }) {
//   let config = getConfig();

//   let localIsOpen =
//     localStorage.getItem('navdrawerstateopen', 'true') === 'true';

//   const [open, setOpen] = useState(localIsOpen);
//   const [navItems, setNavItems] = useState(false);
//   const [applicationList, setapplicationList] = useState([]);
//   const [isLoading, setisLoading] = useState(false);
//   const [currentPath, setCurrentPath] = useState(window.location.pathname);
//   const [categories, setCategories] = useState([]);
//   const theme = useTheme();
//   const [selected, setSelected] = useState(window.location.pathname);
//   const location = useLocation();

//   let getSubNavigation = async (applicationList) => {
//     let _navigation = Object.assign({}, navigation);
//     let children = null;
//     applicationList.forEach((ni) => {
//       let path = ni.key;
//       //let navKey = microApps.filter((row) => row.navPath == path)[0]?.key;

//       // if (navKey in _navigation) {
//       //   children = Object.assign([], _navigation[navKey]);
//       // }
//     });
//     let newItems = Object.assign(applicationList, {});
//     //newItems[1].children = children;
//     return newItems;
//   };

//   useEffect(() => {
//     getSubNavigation(applicationList).then((newNav) => setNavItems(newNav));
//     let itemCategories = applicationList.map((i) => i.category);
//     setCategories([...new Set(itemCategories)]);
//     getApplicationList();
//     setOpen(open);
//     parentCallback(open);
//   }, []);
//   let onItemChange = () => {
//     setCurrentPath(window.location.pathname);
//   };

//   useEffect(() => {
//     setSelected(location.pathname);
//   }, [location]);

//   const getApplicationList = async () => {
//     try {
//       setisLoading(true);
//       let oktaToken = JSON.parse(localStorage.getItem('okta-token-storage'));

//       let response = await Api.get('/getUserPermissions');

//       if (response !== '') {
//         const userIsAdmin = JSON.parse(localStorage.getItem('is-admin'));
//         if (response.data.isAdmin) {
//           localStorage.setItem(
//             'is-admin',
//             JSON.stringify(+response.data.isAdmin)
//           );
//         } else {
//           localStorage.removeItem('is-admin');
//         }
//         let newApplicationList = response.data.permissions.directAccess.map(
//           (applications) => {
//             return getItem(
//               applications.application_name,
//               '/' +
//                 (applications.nav_path === 'ui/smart-cleaning'
//                   ? 'ui/smart-cleaning'
//                   : applications.nav_path),
//               applications.application_name,
//               <span className="material-symbols-outlined">
//                 {applications.icon_url}
//               </span>,
//               [],
//               applications.category
//             );
//           }
//         );

//         newApplicationList.sort((a, b) => a.category.localeCompare(b.category));
//         if (response.data.isAdmin === true) {
//           newApplicationList.push(
//             getItem(
//               'Administration',
//               '/ui/admin',
//               'admin',
//               <AdminPanelSettings />,
//               [],
//               'Admin'
//             )
//           );
//         }

//         setapplicationList(newApplicationList);
//         const categoriesSet = new Set([
//           ...newApplicationList.map((app) => app.category)
//         ]);
//         setCategories(Array.from(categoriesSet));
//         setisLoading(false);
//         return response;
//       } else {
//         window.location.reload(true);
//       }
//     } catch (error) {
//       return {};
//     }
//   };

//   const handleDrawer = () => {
//     setOpen(!open);
//     parentCallback(!open);
//     localStorage.setItem('navdrawerstateopen', !open);
//   };

//   return (
//     <Drawer variant="permanent" open={open}>
//       <Toolbar />
//       <Link
//         to={location.pathname.startsWith('/demo') ? `/demo` : `/`}
//         onClick={() => setSelected('/')}
//         style={{ textDecoration: 'none' }}
//       >
//         <ListItem disablePadding>
//           <ListItemButton
//             sx={{
//               minHeight: 48,
//               justifyContent: open ? 'initial' : 'center',
//               px: 2.5,
//               // background: selected === navItem.link ? '#003d2c1f' : '#fff',
//               mx: '7px',
//               borderRadius: '4px'
//             }}
//           >
//             <ListItemIcon
//               sx={{
//                 minWidth: 0,
//                 mr: open ? 1 : 'auto',
//                 justifyContent: 'center',
//                 color: '#1A1A1A'
//               }}
//             >
//               {/* {navItem.icon} */}
//               <Dashboard />
//             </ListItemIcon>
//             <ListItemText
//               // id={`sidebar-app-redirect-text-${navItem.label}`}
//               primary={'Dashboard'}
//               sx={{ opacity: open ? 1 : 0 }}
//             />
//           </ListItemButton>
//         </ListItem>
//       </Link>
//       {categories.map((category, index) => (
//         <Accordion
//           sx={{ border: 'none', boxShadow: 'none' }}
//           defaultExpanded={true}
//         >
//           {open ? (
//             <AccordionSummary
//               expandIcon={<ExpandMoreIcon />}
//               aria-controls={'panel-content-' + index}
//               id={'panel-header-' + index}
//             >
//               <Typography
//                 sx={{
//                   fontSize: '14px',
//                   color: 'rgba(26, 26, 26, 0.6)',
//                   textTransform: 'capitalize'
//                 }}
//               >
//                 {category}
//               </Typography>
//             </AccordionSummary>
//           ) : (
//             <Divider
//               sx={{
//                 display: open || index === 0 ? 'none' : 'block',
//                 my: '6px'
//               }}
//             />
//           )}
//           <AccordionDetails sx={{ padding: 0 }}>
//             {applicationList
//               .sort((a, b) => (a.label > b.label ? 1 : -1))
//               .map((navItem, index) =>
//                 navItem.category == category ? (
//                   <>
//                     <Link
//                       to={
//                         location.pathname.startsWith('/demo')
//                           ? `/demo${navItem.link}`
//                           : `${navItem.link}`
//                       }
//                       onClick={() => setSelected(navItem.link)}
//                       style={{ textDecoration: 'none' }}
//                     >
//                       <ListItem
//                         key={navItem.key}
//                         disablePadding
//                         sx={{
//                           display: 'block'
//                         }}
//                       >
//                         <ListItemButton
//                           id={`sidebar-app-redirect-list-${navItem.label}`}
//                           sx={{
//                             minHeight: 48,
//                             justifyContent: open ? 'initial' : 'center',
//                             px: 2.5,
//                             background:
//                               selected === navItem.link ? '#003d2c1f' : '#fff',
//                             mx: '7px',
//                             borderRadius: '4px'
//                           }}
//                         >
//                           <ListItemIcon
//                             id={`sidebar-app-redirect-${navItem.label}`}
//                             sx={{
//                               minWidth: 0,
//                               mr: open ? 1 : 'auto',
//                               justifyContent: 'center',
//                               color: '#1A1A1A'
//                             }}
//                           >
//                             {navItem.icon}
//                           </ListItemIcon>
//                           <ListItemText
//                             id={`sidebar-app-redirect-text-${navItem.label}`}
//                             primary={navItem.label}
//                             sx={{ opacity: open ? 1 : 0 }}
//                           />
//                         </ListItemButton>
//                       </ListItem>
//                       <Divider />
//                     </Link>
//                   </>
//                 ) : null
//               )}
//           </AccordionDetails>
//         </Accordion>
//       ))}

//       {
//         <DrawerHeader>
//           <IconButton onClick={handleDrawer} id="sidebar-toggle">
//             {open ? <ChevronLeft /> : <ChevronRight />}
//           </IconButton>
//         </DrawerHeader>
//       }
//     </Drawer>
//   );
// }

// export default NaviationDrawer;
