import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
function SubAppContainer({ useSubApp }) {
  const showSubApp = useSubApp;

  let display = showSubApp ? 'block' : 'none';
  let subappContainer = (
    <main className="subapp-container" id="subapp-container">
      {display == 'block' && (
        <div className="home-page-container ">
          <div className="home-page-loader">
            <CircularProgress sx={{ color: '#003F2D' }} />
          </div>
        </div>
      )}
    </main>
  );

  useEffect(() => {
    display = showSubApp ? 'block' : 'none';
    subappContainer = (
      <main
        style={{ display }}
        className="subapp-container"
        id="subapp-container"
      />
    );
  });

  return subappContainer;
}

export default SubAppContainer;
