import './App.css';
import React, { useEffect, useState } from 'react';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';

import OktaAuth, { userData } from './utils/okta.js';
import { AppLayout } from './components/layouts/AppLayout.jsx';
import { HomePage } from './pages/Home.jsx';
import LoginPage from './pages/Login.jsx';
import LogoutPage from './pages/Logout.jsx';
import { loadAllMicroApps } from './MicroApps.js';

import Api from './utils/Api.js';
import { getConfig } from './config/client/config.js';
import { TanstackReactQueryProvider } from './TanstackReactQueryProvider.jsx';
import { SidebarProvider } from './context/SidebarContext.jsx';
import { NavigationProvider } from './components/NavigationProvider.jsx';
// Segment analytics script
const config = getConfig();
const segmentKey = config.segmentIoKey;
!(function () {
  var analytics = (window.analytics = window.analytics || []);
  if (!analytics.initialize)
    if (analytics.invoked)
      window.console && console.error && console.error('Segment snippet included twice.');
    else {
      analytics.invoked = !0;
      analytics.methods = [
        'trackSubmit',
        'trackClick',
        'trackLink',
        'trackForm',
        'pageview',
        'identify',
        'reset',
        'group',
        'track',
        'ready',
        'alias',
        'debug',
        'page',
        'once',
        'off',
        'on',
        'addSourceMiddleware',
        'addIntegrationMiddleware',
        'setAnonymousId',
        'addDestinationMiddleware'
      ];
      analytics.factory = function (e) {
        return function () {
          if (window.analytics.initialized)
            return window.analytics[e].apply(window.analytics, arguments);
          var i = Array.prototype.slice.call(arguments);
          i.unshift(e);
          analytics.push(i);
          return analytics;
        };
      };
      for (var i = 0; i < analytics.methods.length; i++) {
        var key = analytics.methods[i];
        analytics[key] = analytics.factory(key);
      }
      analytics.load = function (key, i) {
        var t = document.createElement('script');
        t.type = 'text/javascript';
        t.async = !0;
        t.src = 'https://cdn.segment.com/analytics.js/v1/' + key + '/analytics.min.js';
        var n = document.getElementsByTagName('script')[0];
        n.parentNode.insertBefore(t, n);
        analytics._loadOptions = i;
      };
      analytics._writeKey = segmentKey;
      analytics.SNIPPET_VERSION = '4.16.1';
      analytics.load(segmentKey);
      analytics.page();
    }
})();

let AUTHENTICATED = false;
let authEventNotify = async function (authState) {
  AUTHENTICATED = authState.isAuthenticated;
  let user = userData();
  let config = getConfig();
  if (AUTHENTICATED === true && user !== null) {
    try {
      let uuid = await fetchUUID(user.email);

      // Pass UUID to Segment via identify method
      window.analytics.identify(uuid);

      return Api.get(config.adminServiceUrl + '/addUser');
    } catch (error) {
      return {};
    }
  }
};

const fetchUUID = async email => {
  try {
    const response = await fetch(
      `https://cbre-uuid-fa.azurewebsites.net/api/uuid-generator?code=oPCx-ErmxkzsACG-xrPfmGTqQnEBy5HA5ELSUAlwOVK7AzFuwF7pIg==&email=${email}`
    );
    const uuid = await response.text();
    return uuid;
  } catch (error) {
    return null;
  }
};

OktaAuth.init(authEventNotify);

export const App = () => {
  let [authenticated, setAuthenticated] = useState(false);
  /*useEffect(() => { }, [AUTHENTICATED]);
   */ // Temporary Interval. Should strive to use useEffect here
  setInterval(() => {
    setAuthenticated(OktaAuth.isAuthenticated());
  }, 3000);

  loadAllMicroApps();

  return (
    <TanstackReactQueryProvider>
      <NavigationProvider>
        <SidebarProvider>
          {authenticated ? (
            <Router>
              <Routes>
                <Route path='/login/oidc/callback' element={<LoginPage authenticated={true} />} />
                <Route path='/logout/oidc/callback' element={<LogoutPage />} />
                <Route path='/' element={<AppLayout />}>
                  <Route index element={<HomePage />} />
                  <Route path='ui/issue/*' subapp={true} />
                  <Route path='ui/admin/*' subapp={true} />
                  <Route path='ui/maintenance/*' subapp={true} />
                  <Route path='ui/vantage-dashboard/*' subapp={true} />
                  <Route path='ui/reflow/*' subapp={true} />
                  <Route path='ui/cbm/*' subapp={true} />
                  <Route path='ui/smart-cleaning/*' subapp={true} />
                  <Route path='ui/pipe-monitoring/*' subapp={true} />
                  <Route path='*' element={<div>Not Found</div>} />
                </Route>

                {/* provide demo routes as physical page */}
                <Route path='/demo' element={<AppLayout />}>
                  <Route index element={<HomePage />} />
                  <Route path='/demo/ui/issue/*' subapp={true} />
                  <Route path='/demo/ui/admin/*' subapp={true} />
                  <Route path='/demo/ui/maintenance/*' subapp={true} />
                  <Route path='/demo/ui/vantage-dashboard/*' subapp={true} />
                  <Route path='/demo/ui/reflow/*' subapp={true} />
                  <Route path='/demo/ui/cbm/*' subapp={true} />
                  <Route path='/demo/ui/smart-cleaning/*' subapp={true} />
                  <Route path='/demo/ui/pipe-monitoring/*' subapp={true} />
                  <Route path='*' element={<div>Not Found</div>} />
                </Route>
              </Routes>
            </Router>
          ) : (
            <Router>
              <Routes>
                <Route path='/login/oidc/callback' element={<LoginPage />} />
                <Route path='/logout/oidc/callback' element={<LogoutPage />} />
                <Route path='*' element={<LoginPage />} />
              </Routes>
            </Router>
          )}
        </SidebarProvider>
      </NavigationProvider>
    </TanstackReactQueryProvider>
  );
};
export default App;
