import * as React from "react";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";

import { useNavigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { SelectTheme } from "../components/theme/SelectTheme.jsx";
let theme = SelectTheme();
export default (props) => {
  let navigate = useNavigate();

  if (props.authenticated) {
    navigate("/");
  }

  return (
    <ThemeProvider theme={theme}>
      <Stack
        style={{
          justifyContent: "center",
          alignItems: "center",
          height: "100dvh",
          width: "100dvw",
        }}
      >
        <CircularProgress />
      </Stack>
    </ThemeProvider>
  );
};
